import { mdiApple, mdiFacebook, mdiInstagram, mdiSpotify, mdiYoutube } from '@mdi/js';
import Icon from '@mdi/react';
import { IconButton } from 'material-ui';
import React from 'react';
import styled from 'styled-components';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { defaultMuiTheme } from 'context/Theme/MaterialTheme';

const SocialButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4rem;
    margin-right: 2rem;

    ${defaultMuiTheme.breakpoints.down(700)} {
        gap: 1rem;
        margin-right: 1.5rem;
    }

    ${defaultMuiTheme.breakpoints.down(500)} {
        gap: 0.25rem;
        margin-right: 1.5rem;
    }
`;

const SocialIcon = styled(IconButton)`
    height: 4rem;
    width: 4rem;
`;

const StyledIcon = styled(Icon)`
    color: ${(props) => props.theme.colors.white};
    height: 4rem;
    width: 4rem;

    ${defaultMuiTheme.breakpoints.down(700)} {
        height: 3rem;
        width: 3rem;
    }

    &:hover {
        color: ${(props) => props.theme.colors.primary};
    }

    transition: color 0.2s ease-in-out;
`;

export const FACEBOOK_URL = 'https://www.facebook.com/groups/547884327226400/';
export const YOUTUBE_URL = 'https://www.youtube.com/@forgingbrainspodcast';
export const SPOTIFY_URL = 'https://open.spotify.com/show/5sFrEs6lsUVIi4clTS6ot7';
export const APPLE_URL = 'https://podcasts.apple.com/us/podcast/forging-brains-podcast/id1646071859';
export const INSTAGRAM_URL = 'https://www.instagram.com/pnw.farrier/';

const SocialButtons: React.FC = () => {
    return (
        <MuiThemeProvider>
            <SocialButtonContainer>
                <SocialIcon href={FACEBOOK_URL} target="_blank">
                    <StyledIcon path={mdiFacebook} />
                </SocialIcon>
                <SocialIcon href={YOUTUBE_URL} target="_blank">
                    <StyledIcon path={mdiYoutube} />
                </SocialIcon>
                <SocialIcon href={SPOTIFY_URL} target="_blank">
                    <StyledIcon path={mdiSpotify} />
                </SocialIcon>
                <SocialIcon href={APPLE_URL} target="_blank">
                    <StyledIcon path={mdiApple} />
                </SocialIcon>
                <SocialIcon href={INSTAGRAM_URL} target="_blank">
                    <StyledIcon path={mdiInstagram} />
                </SocialIcon>
            </SocialButtonContainer>
        </MuiThemeProvider>
    );
};

export default SocialButtons;
