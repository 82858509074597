import { Typography } from '@material-ui/core';
import { defaultMuiTheme } from 'context/Theme/MaterialTheme';
import {
    ForgingBrainsImageTransparentFull,
    SponsorLogo1,
    SponsorLogo2,
    SponsorLogo3,
    SponsorLogo4
} from 'img/imgconsts';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const PodcastBannerContainer = styled.div`
    margin: 24px;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    padding: 20px;

    max-height: 850px;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.5);
    white-space: nowrap;
    border: 1px solid ${(props) => props.theme.colors.border};

    min-height: 450px;
    max-width: 90%;

    ${defaultMuiTheme.breakpoints.down(1200)} {
        flex-direction: column;
        align-items: center;
    }

    ${defaultMuiTheme.breakpoints.down(700)} {
        min-height: 550px;
    }
`;

const BannerContainerLeft = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const BannerContainerRight = styled.div`
    color: ${(props) => props.theme.colors.white};
    display: flex;
    align-items: center;
    flex-direction: flex;
    flex-direction: column;
    max-width: 700px;
    margin-right: 4rem;

    ${defaultMuiTheme.breakpoints.down(1200)} {
        margin-right: 0;
        max-width: 100%;
    }
`;

const StyledImg = styled.img`
    max-height: 400px;
    max-width: 400px;

    ${defaultMuiTheme.breakpoints.down(700)} {
        max-height: 200px;
        max-width: 200px;
    }
`;

const SponsorImg = styled.img`
    max-height: 225px;
    max-width: 225px;
    margin: 1rem;

    ${defaultMuiTheme.breakpoints.down(700)} {
        margin: 0.5rem;
        max-height: 150px;
        max-width: 150px;
    }
`;

const StyledHeader = styled.h2`
    color: ${(props) => props.theme.colors.white};
    font-weight: 600;
    font-size: 2.25rem;
    margin: 0;

    ${defaultMuiTheme.breakpoints.down(700)} {
        font-size: 1.75rem;
    }

    ${defaultMuiTheme.breakpoints.down(500)} {
        font-size: 1.25rem;
    }
`;

const CenteredTypography = styled(Typography)`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
`;

const HostedBy = styled.p`
    color: ${(props) => props.theme.colors.white};
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 0;

    ${defaultMuiTheme.breakpoints.down(700)} {
        margin-top: 0.5rem;
        font-size: 1.125rem;
        text-wrap: wrap;
    }

    ${defaultMuiTheme.breakpoints.down(500)} {
        font-size: 1rem;
    }
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: wrap;
`;

const SponsorBanner: React.FC = () => {
    return (
        <Wrapper>
            <PodcastBannerContainer>
                <BannerContainerLeft>
                    <StyledImg src={ForgingBrainsImageTransparentFull} />
                </BannerContainerLeft>
                <BannerContainerRight>
                    <StyledHeader>Check out our awesome sponsors!</StyledHeader>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <CenteredTypography variant="body1">
                            <a href="https://www.yukonforge.com/" target="_blank" rel="noreferrer">
                                <SponsorImg src={SponsorLogo4} />
                            </a>
                            <a href="https://farrierbox.com/home" target="_blank" rel="noreferrer">
                                <SponsorImg src={SponsorLogo3} />
                            </a>
                            <a href="https://www.well-shod.com/" target="_blank" rel="noreferrer">
                                <SponsorImg src={SponsorLogo1} />
                            </a>

                            <a href="https://www.worldchampionshipblacksmiths.com/" target="_blank" rel="noreferrer">
                                <SponsorImg src={SponsorLogo2} />
                            </a>
                        </CenteredTypography>
                    </div>
                    <HostedBy>
                        Use code <b>BRAINS</b> for 10% off at checkout!
                    </HostedBy>
                </BannerContainerRight>
            </PodcastBannerContainer>
        </Wrapper>
    );
};

export default SponsorBanner;
