import { Backdrop, IconButton } from '@material-ui/core';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { DarkModeButton } from 'components/buttons/DarkModeButton';
import { NavButton } from 'components/buttons/NavButton';
import { EmberParticles } from 'components/particles/embers';
import SocialButtons from 'components/social/SocialButtons';
import { NavContext, NavMode } from 'context/Nav';
import { ThemeMode, useThemeContext } from 'context/Theme';
import { defaultMuiTheme } from 'context/Theme/MaterialTheme';
import React, { useContext } from 'react';
import styled, { css, keyframes } from 'styled-components';

const glowAnimation = keyframes`
    0% {
        box-shadow: 0 0 10px rgba(255, 165, 0, 0.8); /* Start with a subtle glow */
    }
    50% {
        box-shadow: 0 0 20px rgba(255, 165, 0, 0.9); /* Increase the glow intensity */
    }
    100% {
        box-shadow: 0 0 10px rgba(255, 165, 0, 0.8); /* Return to subtle glow */
    }
`;

const SidebarContainer = styled.div<{ $visible: boolean; $themeMode: ThemeMode }>`
    width: ${(props) => (props.$visible ? '75vw' : '0')};
    height: 100vh;
    position: fixed;
    //backgroud color of slightly transparent black
    justify-content: space-between;

    background-color: ${(props) =>
        props.$themeMode === ThemeMode.LIGHT ? 'rgba(255, 255, 255, 0.95)' : 'rgba(0, 0, 0, 0.95)'};
    z-index: 15;
    visibility: ${(props) => (props.$visible ? 'visible' : 'hidden')};
    left: ${(props) => (props.$visible ? '0' : '-100%')};
    transition: all 0.5s ease-in-out;
    border-right: 1px solid ${(props) => props.theme.colors.border};
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    border-top: 1px solid ${(props) => props.theme.colors.border};

    overflow: hidden;

    ${(props) =>
        props.$visible &&
        css`
            animation: ${glowAnimation} 2s infinite alternate;
        `}
`;

const SidebarHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};

    ${defaultMuiTheme.breakpoints.down(700)} {
        height: 75px;
    }
`;

const SidebarContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 24px;
    padding: 2rem 0;
    position: relative;
    z-index: 2;
`;

const StyledIcon = styled(Icon)`
    color: ${(props) => props.theme.colors.steel};
`;

const ForgingBrainsText = styled.div`
    font-size: 4rem;
    position: relative;
    display: flex;
    white-space: nowrap;
    align-items: center;
    top: 6px;
    text-shadow: 0 0 5px orange;
    padding-right: 1rem;

    ${defaultMuiTheme.breakpoints.down(700)} {
        font-size: 2.25rem;
    }
`;

const Divider = styled.hr`
    width: 60%;
    height: 1px;
    color: ${(props) => props.theme.colors.border};
    margin: 1rem;

    ${defaultMuiTheme.breakpoints.down(600)} {
        width: 50%;
    }
`;

const StyledBackground = styled(Backdrop)`
    z-index: 0;
`;

export const Sidebar = () => {
    const { navMode, setNavMode } = useContext(NavContext);
    const { themeMode } = useThemeContext();

    return (
        <SidebarContainer $visible={navMode === NavMode.OPEN} $themeMode={themeMode}>
            <div style={{ position: 'relative', zIndex: 1 }}>
                <SidebarHeader>
                    <IconButton onClick={() => setNavMode(navMode === NavMode.CLOSED ? NavMode.OPEN : NavMode.CLOSED)}>
                        <StyledIcon path={mdiClose} size={2.5} />
                    </IconButton>
                    <DarkModeButton />
                </SidebarHeader>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <SidebarContentContainer>
                        <NavButton label={'About'} destination="/" />
                        <NavButton label={'Listen'} destination="/listen" />
                        <NavButton label={'Shop'} destination="/shop" />
                        <NavButton label={'Contact'} destination="/contact" />
                        <SocialButtons />
                    </SidebarContentContainer>
                    <Divider />
                    <ForgingBrainsText className="astriangle">Forging Brains</ForgingBrainsText>
                </div>
            </div>
            <StyledBackground open={navMode === NavMode.OPEN} onClick={() => setNavMode(NavMode.CLOSED)} />
        </SidebarContainer>
    );
};
