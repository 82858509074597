import React from 'react';

import styled from 'styled-components';

const LoaderWrapper = styled.div`
    display: flex;
    color: red;
`;

export const RouterFallback: React.FC = () => {
    return <LoaderWrapper>One moment...</LoaderWrapper>;
};
