import { ReactElement } from 'react';

import styled from 'styled-components';
import React from 'react';

const Container = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
`;

const Title = styled.h1`
    letter-spacing: 5px;
`;

const ComingSoon = (): ReactElement => {
    return (
        <Container>
            <Title>COMING SOON!</Title>
        </Container>
    );
};

export default ComingSoon;
