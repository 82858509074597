import React, { createContext, useState, useEffect, useContext, PropsWithChildren } from 'react';
import { MuiThemeProvider, StylesProvider, createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import LightTheme from './LightTheme';
import DarkTheme from './DarkTheme';
import { FBrainsTheme } from './MaterialTheme';

export enum ThemeMode {
    LIGHT = 'light',
    DARK = 'dark'
}

interface IThemeContext {
    themeMode: ThemeMode;
    setThemeMode: React.Dispatch<React.SetStateAction<ThemeMode>>;
}

export const ThemeContext = createContext<IThemeContext>({
    themeMode: ThemeMode.LIGHT,
    setThemeMode: () => {}
});

interface ThemeProviderProps {
    children: React.ReactNode;
}

export const ThemeProviderComponent: React.FC<ThemeProviderProps> = ({
    children
}: PropsWithChildren<ThemeProviderProps>) => {
    const [themeMode, setThemeMode] = useState<ThemeMode>(ThemeMode.DARK);

    useEffect(() => {
        const storedThemeMode = localStorage.getItem('themeMode') || ThemeMode.DARK;

        if (storedThemeMode === ThemeMode.DARK) {
            setThemeMode(ThemeMode.DARK);
        } else {
            setThemeMode(ThemeMode.LIGHT);
        }
    }, []);

    useEffect(() => {
        const updateTheme = (e: StorageEvent) => {
            if (e.storageArea === localStorage && e.key === 'themeMode') {
                const storedThemeMode = localStorage.getItem('themeMode') || ThemeMode.LIGHT;
                if (storedThemeMode === ThemeMode.DARK) {
                    setThemeMode(ThemeMode.DARK);
                }
                if (storedThemeMode === ThemeMode.LIGHT) {
                    setThemeMode(ThemeMode.LIGHT);
                }
            }
        };

        window.addEventListener('storage', updateTheme);

        return () => {
            window.removeEventListener('storage', updateTheme);
        };
    }, []);

    const theme = themeMode === ThemeMode.LIGHT ? LightTheme : DarkTheme;

    return (
        <ThemeContext.Provider value={{ themeMode, setThemeMode }}>
            <StylesProvider injectFirst>
                <MuiThemeProvider theme={FBrainsTheme(theme)}>
                    <ThemeProvider theme={theme}>{children}</ThemeProvider>
                </MuiThemeProvider>
            </StylesProvider>
        </ThemeContext.Provider>
    );
};

export const useThemeContext = () => {
    const context = useContext(ThemeContext);

    if (!context) {
        throw new Error('useThemeContext must be used within ThemeContextProvider');
    }

    return context;
};
