import React, { useContext } from 'react';

import styled from 'styled-components';
import { DarkModeButton } from '../buttons/DarkModeButton';
import { ForgingBrainsIcon } from 'components/buttons/FBIconNav';
import { NavButton } from 'components/buttons/NavButton';
import { Link } from 'react-router-dom';
import { useMediaQuery, IconButton } from '@material-ui/core';
import { defaultMuiTheme } from 'context/Theme/MaterialTheme';

import { mdiMenu } from '@mdi/js';
import Icon from '@mdi/react';
import { NavContext, NavMode } from 'context/Nav';

export const APP_HEADER_HEIGHT = 90;

const HeaderContainer = styled.div`
    position: fixed;
    background-color: ${(props) => props.theme.colors.surface};
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: ${APP_HEADER_HEIGHT}px;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    z-index: 2;
    margin-bottom: 1px;
`;

const HeaderLeft = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    grid-column: 1;
`;

const HeaderCenter = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 100%;
    grid-column: 2;
    gap: 24px;
`;

const HeaderRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    grid-column: 3;
    gap: 24px;
    margin-right: 24px;
`;

const ForgingBrainsHeaderText = styled.div`
    font-size: 5rem;
    position: relative;
    display: flex;
    white-space: nowrap;
    align-items: center;
    top: 12px;
    text-shadow: 0 0 5px orange;

    ${defaultMuiTheme.breakpoints.down(700)} {
        font-size: 2.875rem;
        margin-bottom: 1rem;
    }
`;

const StyledIcon = styled(Icon)`
    color: ${(props) => props.theme.colors.steel};

    height: 4rem;
    width: 4rem;

    ${defaultMuiTheme.breakpoints.down(700)} {
        height: 3rem;
        width: 3rem;
    }
`;

interface HeaderProps {
    hideNav?: boolean;
    hideHeaderLogo?: boolean;
    backButton?: boolean;
    backButtonAction?: () => void;
    backButtonIconPath?: string;
    backButtonTooltip?: string;
}

const Header: React.FC<HeaderProps> = () => {
    const { navMode, setNavMode } = useContext(NavContext);

    const sidebarBreakpoint = useMediaQuery(defaultMuiTheme.breakpoints.down(1200));

    return (
        <HeaderContainer>
            <HeaderLeft>
                {sidebarBreakpoint ? (
                    <IconButton onClick={() => setNavMode(navMode === NavMode.CLOSED ? NavMode.OPEN : NavMode.CLOSED)}>
                        <StyledIcon path={mdiMenu} />
                    </IconButton>
                ) : null}
            </HeaderLeft>
            {sidebarBreakpoint ? null : (
                <HeaderCenter>
                    {/**<NavButton label={'News'} destination="/" />*/}
                    <NavButton label={'About'} destination="/" />
                    <NavButton label={'Listen'} destination="/listen" />
                    <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <ForgingBrainsHeaderText className="astriangle">Forging Brains</ForgingBrainsHeaderText>
                    </Link>
                    <NavButton label={'Shop'} destination="/shop" />
                    <NavButton label={'Contact'} destination="/contact" />
                    {/*<NavButton label={'Listen'} destination="/listen" />*/}
                </HeaderCenter>
            )}
            <HeaderRight>
                {sidebarBreakpoint ? (
                    <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <ForgingBrainsHeaderText className="astriangle">Forging Brains</ForgingBrainsHeaderText>
                    </Link>
                ) : (
                    <DarkModeButton />
                )}
            </HeaderRight>
        </HeaderContainer>
    );
};

export default Header;
