import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

interface NavButtonProps {
    label: string;
    destination: string;
}

const flicker = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
    }
`;

const pulse = keyframes`
    0% {
        box-shadow: 0 0 0 0 rgba(255, 149, 0, 0.7);
    }
    70% {
        box-shadow: 0 0 0 25px rgba(255, 149, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 149, 0, 0);
    }
`;

const StyledButton = styled(Button)`
    background-color: ${(props) => props.theme.colors.steel};
    color: #ffffff;
    font-size: 1rem;
    font-weight: 700;
    padding: 0px 20px;
    border: 2px solid transparent;
    border-radius: 5px;
    box-shadow: 0 0 0 0 rgba(255, 149, 0, 0.7);
    transition: all 0.2s ease-in-out;

    &:hover {
        animation: ${flicker} 1s ease-in-out infinite alternate;
        animation-delay: 0.1s;
        background-color: #ff9500;
        box-shadow: 0 0 0 10px rgba(254, 254, 188, 0.7);
        color: #434343;
        transition: all 0.2s ease-in-out;
    }

    &:active {
        background-color: #ff6b00;
        box-shadow: none;
        color: #ffffff;
    }

    &:focus {
        outline: none;
    }

    &:focus-visible {
        border: 2px solid #ff9500;
    }

    &:hover:after {
        content: '';
        position: absolute;
        top: -5px;
        left: -5px;
        right: -5px;
        bottom: -5px;
        border: 2px solid #ff9500;
        border-radius: 5px;
        animation: ${pulse} 2s linear infinite;
        animation-delay: -0.2s;
    }

    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
`;

const StyledTypography = styled(Typography)`
    font-weight: 600;
    font-size: 2rem;
    position: relative;
    top: 4px;
    right: 2px;
`;

export const NavButton: React.FC<NavButtonProps> = ({ label, destination }) => {
    return (
        <Link to={destination}>
            <StyledButton color="primary" variant="contained" disableElevation>
                <StyledTypography variant="body1" className="astriangle">
                    {label}
                </StyledTypography>
            </StyledButton>
        </Link>
    );
};
