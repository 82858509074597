export const ForgingBrainsImage = require('./forgingbrainslogo.jpg');
export const ForgePicture = require('./forge_1.png');
export const ForgePicture2 = require('./forge_2.png');
export const ForgingBrainsImageTransparent = require('./forgingbrainslogotransparent.png');
export const ForgingBrainsImageTransparentFull = require('./forgingbrainslogofull.png');

export const ForgingBrainsScene_1 = require('./scene_4.png');
export const ForgingBrainsScene_2 = require('./scene_5.png');
export const ForgingBrainsScene_3 = require('./scene_6.png');

export const YoutubeLogo = require('./youtubelogo.png');
export const SpotifyLogo = require('./spotifylogo.png');
export const ApplePodcastLogo = require('./applepodcastlogo.png');

export const SponsorLogo1 = require('./wellshodlogo.jpg');
export const SponsorLogo2 = require('./worldchampionblacksmithslogo.jpg');
export const SponsorLogo3 = require('./farrierboxlogo.png');
export const SponsorLogo4 = require('./yukon_forge.png');
