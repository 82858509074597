import { Backdrop, IconButton } from '@material-ui/core';
import { mdiPlay } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import YouTube from 'react-youtube';
import styled from 'styled-components';

interface VideoProps {
    link: string;
    guid: string;
    title: string;
}

const VideoWrapper = styled.div`
    position: relative;
    max-width: 400px;
    min-height: 400px;
    background-color: ${(props) => props.theme.colors.surface};
    padding: 0.5rem;
    border-radius: 5px;
`;

const StyledImg = styled.img`
    display: inline-block;
    width: 100%;
    height: 75%;
    margin: -11% 0;
`;

const ImgWrapper = styled.div`
    display: inline-block;
    width: 100%;
    height: 75%;
    overflow: hidden;
`;

const VideoTitle = styled.h2``;

const StyledIcon = styled(Icon)`
    color: ${(props) => props.theme.colors.primary};
`;

const StyledIconButton = styled(IconButton)`
    position: absolute;
    top: 19%;
    left: 41%;
    background-color: ${(props) => props.theme.colors.surface};

    &:hover {
        background-color: ${(props) => props.theme.colors.bottomSurface};
    }
`;

const StyledYoutubePlayer = styled(YouTube)<{ $active: boolean }>`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;

    opacity: ${(props) => (props.$active ? '1' : '0')};

    transition: all 0.3s ease-in-out;

    height: auto !important;
    width: 90% !important;
    max-width: 870px;
    aspect-ratio: 16 / 9;

    iframe {
        height: 100%;
        width: 100%;
    }
`;

const StyledBackground = styled(Backdrop)`
    z-index: 5;
`;

function replaceAmpersand(inputString) {
    // Use the replace method with a regular expression to globally replace &amp; with &
    const resultString = inputString.replace(/&amp;/g, '&');
    const resultString2 = resultString.replace(/&quot;/g, '"');
    return resultString2;
}

export const Video: React.FC<VideoProps> = ({ link, guid, title }) => {
    const [isActive, setIsActive] = useState(false);

    // Rest of your component code

    const videoOptions = {
        playerVars: {
            autoplay: 1,
            controls: 1,
            rel: 0,
            showinfo: 1,
            mute: 0,
            loop: 0
        }
    };

    return (
        <>
            {isActive ? (
                <>
                    <StyledYoutubePlayer videoId={guid.split(':')[2]} opts={videoOptions} $active={isActive} />
                    <StyledBackground open={isActive} onClick={() => setIsActive(false)} />
                </>
            ) : null}
            <VideoWrapper>
                <div>
                    <ImgWrapper>
                        <StyledImg src={`https://img.youtube.com/vi/${guid.split(':')[2]}/hqdefault.jpg`} />
                    </ImgWrapper>
                </div>
                <StyledIconButton onClick={() => setIsActive(true)}>
                    <StyledIcon path={mdiPlay} size={2.5} />
                </StyledIconButton>
                <VideoTitle>{replaceAmpersand(title)}</VideoTitle>
            </VideoWrapper>
        </>
    );
};
