import { Button, Typography } from '@material-ui/core';
import SocialButtons, { APPLE_URL, SPOTIFY_URL, YOUTUBE_URL } from 'components/social/SocialButtons';
import { defaultMuiTheme } from 'context/Theme/MaterialTheme';
import { ApplePodcastLogo, ForgingBrainsImageTransparentFull, SpotifyLogo, YoutubeLogo } from 'img/imgconsts';
import React from 'react';
import styled from 'styled-components';

const PodcastBannerContainer = styled.div`
    margin: 24px;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    padding: 20px;

    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.5);
    white-space: nowrap;
    border: 1px solid ${(props) => props.theme.colors.border};

    min-height: 450px;
    max-width: 90%;

    ${defaultMuiTheme.breakpoints.down(1200)} {
        flex-direction: column;
        align-items: center;
    }

    ${defaultMuiTheme.breakpoints.down(700)} {
        min-height: 550px;
    }
`;

const BannerContainerLeft = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const BannerContainerRight = styled.div`
    color: ${(props) => props.theme.colors.white};
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 700px;
    margin-right: 4rem;

    ${defaultMuiTheme.breakpoints.down(1200)} {
        margin-right: 0;
    }
`;

const StyledImg = styled.img`
    max-height: 400px;
    max-width: 400px;

    ${defaultMuiTheme.breakpoints.down(700)} {
        max-height: 200px;
        max-width: 200px;
    }
`;

const StyledHeader = styled.h2`
    color: ${(props) => props.theme.colors.white};
    font-weight: 600;
    font-size: 2.5rem;
    margin: 0;
    margin-bottom: 2rem;

    ${defaultMuiTheme.breakpoints.down(700)} {
        font-size: 1.75rem;
    }

    ${defaultMuiTheme.breakpoints.down(550)} {
        font-size: 1.5rem;
    }
`;

const HostedBy = styled.p`
    color: ${(props) => props.theme.colors.white};
    font-size: 2rem;
    margin: 0;

    ${defaultMuiTheme.breakpoints.down(700)} {
        font-size: 1.25rem;
        white-space: nowrap;
    }

    ${defaultMuiTheme.breakpoints.down(500)} {
        font-size: 1rem;
        white-space: nowrap;
    }
`;

const CenteredTypography = styled(Typography)`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
`;

const StyledLinkImage = styled.img`
    max-height: 40%;
    margin: 0;
    height: 70px;
    margin-right: 1rem;
    margin-bottom: 1rem;

    ${defaultMuiTheme.breakpoints.down(700)} {
        margin-bottom: 0.5rem;
        max-height: 40px;
        margin-right: 0;
    }
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const PodcastBanner: React.FC = () => {
    return (
        <Wrapper>
            <PodcastBannerContainer>
                <BannerContainerLeft>
                    <StyledImg src={ForgingBrainsImageTransparentFull} />
                </BannerContainerLeft>
                <BannerContainerRight>
                    <StyledHeader>Listen to the Show on:</StyledHeader>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <CenteredTypography variant="body1">
                            <a href={YOUTUBE_URL} target="_blank" rel="noopener noreferrer">
                                <StyledLinkImage src={YoutubeLogo} />
                            </a>
                            <a href={SPOTIFY_URL} target="_blank" rel="noopener noreferrer">
                                <StyledLinkImage src={SpotifyLogo} />
                            </a>
                            <a href={APPLE_URL} target="_blank" rel="noopener noreferrer">
                                <StyledLinkImage src={ApplePodcastLogo} />
                            </a>
                        </CenteredTypography>
                    </div>
                    <HostedBy>With Gavine Cooper and Riley Kirkpatrick</HostedBy>
                </BannerContainerRight>
            </PodcastBannerContainer>
        </Wrapper>
    );
};

export default PodcastBanner;
