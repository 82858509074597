import { lighten } from 'polished';

import { sharedTheme, PaletteModeType } from './SharedTheme';

// @TODO - replace colors like white and black throughout app with more generic names that work in both light and dark mode
const primaryLightColors = {
    black: '#000a12' /* needs replacing throughout app */,

    white: '#FFF' /* replace with surface and floating surface */,
    surface: '#FFF',
    floatingSurface: '#FFF',

    solidHover: '#f2f4f5',
    whiteHover: '#f2f4f5',
    primary: '#F78801',
    secondary: '#6D757A',
    secondaryLighter: '#f29018',
    border: '#D1DADF',

    background: '#F9FAFA' /* replace with bottom surface */,
    bottomSurface: '#D9DBDC',
    backgroundSecondary: '#37474f',

    text: 'rgba(26, 35, 39, 0.95)' /* replace with textPrimary */,
    textPrimary: 'rgba(26, 35, 39, 0.95)',
    textSecondary: 'rgba(26, 35, 39, 0.8)',
    textTertiary: 'rgba(26, 35, 39, 0.65)',
    textDisabled: 'rgba(26, 35, 39, 0.38)',

    error: '#D32F2F',
    warning: '#F57C00',
    info: '#1976d2',
    success: '#338437',

    videoBackground: '#000',
    videoMenuBackground: '#fff' /* replace with surface or floating surface */,
    videoBackgroundHover: '#9e9e9e',
    videoLoading: '#455060',
    videoActive: '#42a5f5',
    videoBorder: '#9aadc7',
    videoBorderActive: '#2196f3',

    cardBoxShadow:
        '0 0 5px 0 rgb(55 71 79 / 14%), 0 1px 10px 0 rgb(55 71 79 / 12%), 0 2px 4px -1px rgb(55 71 79 / 30%)',

    dropShadow: 'rgba(0, 0, 0, 0.2)',
    darkDropShadow: '#757575',
    codeBackground: 'rgba(0, 0, 0, 0.05)',
    icon: '#abb5bb',
    mutedIcon: '#C0CACF',
    actionHover: 'rgba(26, 35, 39, 0.05)',
    actionFocus: 'rgba(26, 35, 39, 0.12)',
    actionActive: 'rgba(26, 35, 39, 0.09)',
    actionDisabled: 'rgba(26, 35, 39, 0.26)',
    selectedActionDisabled: '#81bbde',
    pollViewerBorderHover: '#2a9fd8',
    pollViewerTextColorHover: '#0277BD',
    pollViewerBackgroundColorHover: 'rgba(2,119,189,0.1)',
    lightGrey: '#8f9799',
    secondaryLightGrey: '#8B959C',
    darkGrey: '#d1dadf',
    errorText: '#B71C1C',
    componentHighlight: '#ECEFF1',
    previewHighlight: '#E1F5FE',
    navItemHighlight: 'rgba(55, 71, 79, 0.1)',
    link: '#047bbc',

    glow: '#FDFDB4',
    steel: '#6D757A'
};

// colors derived from primary light colors
const derivedLightColors = {
    primaryLighter: lighten(0.1, primaryLightColors.primary)
};

export const lightColors = {
    ...primaryLightColors,
    ...derivedLightColors
};

export const LightPaletteTheme = {
    type: PaletteModeType.light,
    colors: lightColors,
    ...sharedTheme
};

export default LightPaletteTheme;
