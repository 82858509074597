import { FormControlLabel, Switch, Typography } from '@material-ui/core';
import { ThemeMode, useThemeContext } from 'context/Theme';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '@mdi/react';
import { mdiWeatherSunny, mdiMoonWaningCrescent } from '@mdi/js';
import IconSize from 'utils/sizes';

const StyledSwitch = styled(Switch)`
    & .MuiSwitch-thumb {
        color: red;
        height: 20px;
        width: 20px;
        background-color: red;
    }

    & .MuiIconButton-label {
        background-color: ${(props) => props.theme.colors.steel};
        border-radius: 50%;
        width: 28px;
        height: 28px;
        transform: translate(-4px, -3px);
    }

    //increase the track width
    & .MuiSwitch-track {
        height: 16px;
        background-color: ${(props) => props.theme.colors.steel};
    }
`;

export const DarkModeButton: React.FC = () => {
    const { themeMode, setThemeMode } = useThemeContext();

    const toggleTheme = () => {
        const newThemeMode = themeMode === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT;
        setThemeMode(newThemeMode);
        localStorage.setItem('themeMode', newThemeMode);
    };

    return (
        <FormControlLabel
            control={
                <StyledSwitch
                    checked={themeMode === 'dark'}
                    onChange={toggleTheme}
                    icon={<Icon path={mdiWeatherSunny} size={IconSize.Heroic} color={'orange'} />}
                    checkedIcon={<Icon path={mdiMoonWaningCrescent} size={1} color="#F0C76B" />}
                    name="themeSwitch"
                />
            }
            label={<Typography variant="overline">{themeMode === 'dark' ? 'Dark' : 'Light'}</Typography>}
        />
    );
};
