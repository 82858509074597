import { ReactElement } from 'react';

import AppLayout from '../layouts/AppLayout';
import React from 'react';

import Carousel from 'react-material-ui-carousel';
import { ForgePicture, ForgingBrainsScene_1, ForgingBrainsScene_2, ForgingBrainsScene_3 } from 'img/imgconsts';
import { Parallax } from 'react-parallax';
import styled from 'styled-components';
import PodcastBanner from 'components/podcast/PodcastBanner';
import { ImageWithText } from 'components/containers/ImageWithText';
import SponsorBanner from 'components/podcast/SponsorBanner';

const StyledParallax = styled(Parallax)`
    display: flex;
    align-items: center;
    margin: 0 -1rem;
    height: max-content;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};

    .react-parallax-content {
        width: 100%;
    }

    .react-parallax-bgimage {
        height: max-content !important;
        top: 0 !important;
    }
`;

const StyledCarousel = styled(Carousel)`
    width: 100%;
`;

const TITLE_1 = 'Straight From the Forge';
const TITLE_2 = 'Interviews with Real People';
const TITLE_3 = 'Made By Farriers';

const BODY_1 = [
    "Step into the world of blacksmithing and farriery with 'Forging Brains,' the podcast hosted by real farriers. Join us as we embark on a journey with real people and dedicated artisans, uncovering the artistry and craftsmanship that shapes this age-old trade.",
    "Our podcast takes you behind the scenes of the forge, where the clang of hammers and the roar of the forge are the soundtrack to hard work and determination. Whether you're a seasoned blacksmith, an aspiring farrier, or simply curious about the dedication and skill behind every horseshoe, 'Forging Brains' is your window into the captivating world of anvil, fire, and the brilliant minds that forge them."
];

const BODY_2 = [
    'Join us on the Forging Brains Podcast as we dive deep into the lives and stories of everyday Americans who embody the true essence of hard work and dedication. Our podcast is a window into the lives of those who roll up their sleeves, face challenges head-on, and exemplify the unwavering American work ethic.',
    "From blue - collar heroes to small business owners, we bring you their inspiring journeys, triumphs, and the unbreakable spirit that defines our great nation.If you're curious about the heart and soul of America and the incredible people who drive it forward, this podcast is your backstage pass to their stories, dreams, and the grit that makes them shine."
];

const BODY_3 = [
    'Together, Gavine and Riley possess a comprehensive understanding of the world of blacksmithing and farriery. Their firsthand experience in the field, coupled with their dedication to preserving and celebrating the rich heritage of this art, ensures that every episode of the Forging Brains Podcast offers listeners a unique perspective on the craftsmanship, dedication, and creativity that define the world of farriery.',
    'Join them on their journey as they share stories, insights, and the enduring spirit of hard work that forges the heart of their beloved craft.'
];

const About = (): ReactElement => {
    const carouselItems = [<PodcastBanner />, <SponsorBanner />];

    return (
        <AppLayout imagesrc={ForgePicture} padTop={false}>
            {ForgePicture ? (
                <StyledParallax bgImage={ForgePicture} bgImageAlt={'Forge Image'} strength={-200}>
                    <StyledCarousel nav>{carouselItems.map((item, i) => item)}</StyledCarousel>
                </StyledParallax>
            ) : null}

            <ImageWithText title={TITLE_1} body={BODY_1} imageUrl={ForgingBrainsScene_1} textOrientation="right" />
            <ImageWithText title={TITLE_2} body={BODY_2} imageUrl={ForgingBrainsScene_3} textOrientation="left" />
            <ImageWithText title={TITLE_3} body={BODY_3} imageUrl={ForgingBrainsScene_2} textOrientation="right" />
        </AppLayout>
    );
};

export default About;
