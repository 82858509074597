import modularScale from '../../utils/modularScale';
import { lightColors, LightPaletteTheme } from './LightTheme';

export const sharedTheme = {
    ms: (v: number): string => modularScale(v),
    fonts: {
        primaryFontFamily: "'Open Sans', sans-serif",
        secondaryFontFamily: "'Montserrat', sans-serif",
        fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
        codeFontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        textSizeSmall: modularScale(-1),
        textSizeStandard: modularScale(0),
        textSizeXXL: modularScale(5),
        textSizeXL: modularScale(4),
        textSizeH1: modularScale(3),
        textSizeH2: modularScale(2),
        textSizeH3: modularScale(1)
    },
    weight: {
        semiBold: 500,
        bold: 600
    }
};

export enum PaletteModeType {
    light = 'light',
    dark = 'dark'
}

export type ColorsType = typeof lightColors;

export type ThemeType = typeof LightPaletteTheme;
