import React, { useState, useEffect } from 'react';
import { Video } from './Video';
import styled from 'styled-components';

const FORGING_BRAINS_CHANNEL_ID = 'UCKSmJfBhmItcxniD9xmeAVA';

interface video {
    title: string;
    guid: string;
    link: string;
}

const VideosWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
`;

export const Videos: React.FC = () => {
    const [videos, setVideos] = useState<video[]>([]);

    const baseUrl =
        'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.youtube.com%2Ffeeds%2Fvideos.xml%3Fchannel_id%3D';

    useEffect(() => {
        (async () => {
            if (FORGING_BRAINS_CHANNEL_ID) {
                try {
                    const data = await fetch(`${baseUrl}${FORGING_BRAINS_CHANNEL_ID}`).then((response) =>
                        response.json()
                    );
                    if (!data.items) {
                        throw new Error();
                    }

                    setVideos(data.items);
                } catch (error) {
                    console.log(error);
                }
            }
        })();
    }, [FORGING_BRAINS_CHANNEL_ID]);

    return (
        <VideosWrapper>
            {videos.map((video) => (
                <Video key={video.guid} title={video.title} guid={video.guid} link={video.link} />
            ))}
        </VideosWrapper>
    );
};
