import React, { createContext, useState, useContext, PropsWithChildren } from 'react';

export enum NavMode {
    OPEN = 'open',
    CLOSED = 'closed'
}

export enum FloatNavMode {
    OPEN = 'open',
    CLOSED = 'closed'
}

interface INavContext {
    navMode: NavMode;
    floatNavMode: FloatNavMode;
    setNavMode: React.Dispatch<React.SetStateAction<NavMode>>;
    setFloatNavMode: React.Dispatch<React.SetStateAction<FloatNavMode>>;
}

export const NavContext = createContext<INavContext>({
    navMode: NavMode.CLOSED,
    floatNavMode: FloatNavMode.CLOSED,
    setNavMode: () => {},
    setFloatNavMode: () => {}
});

interface NavProviderProps {
    children: React.ReactNode;
}

export const NavProvider: React.FC<NavProviderProps> = ({ children }: PropsWithChildren<NavProviderProps>) => {
    const [navMode, setNavMode] = useState<NavMode>(NavMode.CLOSED);
    const [floatNavMode, setFloatNavMode] = useState<FloatNavMode>(FloatNavMode.CLOSED);

    return (
        <NavContext.Provider value={{ navMode, floatNavMode, setNavMode, setFloatNavMode }}>
            {children}
        </NavContext.Provider>
    );
};

export const useNavContext = () => {
    const context = useContext(NavContext);

    if (!context) {
        throw new Error('useNavContext must be used within NavProvider');
    }

    return context;
};
