import React from 'react';

import styled from 'styled-components';

import Header, { APP_HEADER_HEIGHT } from '../components/navigation/Header';
import { defaultMuiTheme } from '../context/Theme/MaterialTheme';
import { Analytics } from '@vercel/analytics/react';
import Footer from './Footer';
import { NavProvider } from 'context/Nav';
import { Sidebar } from 'components/navigation/Sidebar';
import { EmberParticles } from 'components/particles/embers';

interface AppLayoutProps {
    withoutPadding?: boolean;
    hasSubNav?: boolean;
    pageTitle?: string;
    backButton?: boolean;
    fullHeight?: boolean;
    padTop?: boolean;
    hideNav?: boolean;
    hideHeaderLogo?: boolean;
    mainContentOverflow?: boolean;
    backgroundColor?: string;
    children?: React.ReactNode;
    imagesrc?: string;
    imagealt?: string;
}

const AppContent = styled.div<AppLayoutProps>`
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.bottomSurface};
    transition: padding-left 220ms cubic-bezier(0.15, 1, 0.3, 1);
    overflow-y: auto;
    position: relative;
    top: ${APP_HEADER_HEIGHT}px;
    padding-bottom: ${APP_HEADER_HEIGHT}px;
`;

const MainContentWrapper = styled.div<AppLayoutProps>`
    padding: 0;
    ${defaultMuiTheme.breakpoints.up('sm')} {
        padding: ${(props) => (props.withoutPadding ? '0' : props.padTop ? '2rem' : '0 1rem')};
    }
    align-items: center;
    height: ${(props) => (props.fullHeight ? '100%' : 'auto')};
    overflow-y: ${(props) => (props.mainContentOverflow ? 'auto' : 'hidden')};
    background-color: ${(props) => props.theme.colors.bottomSurface};
    display: flex;
    overflow-x: hidden;
    min-height: 70vh;
    flex-flow: column;
`;

const AppLayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const InnerContainer = styled.div`
    display: flex;
    padding-bottom: 5rem;
`;

const FooterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: ${(props) => props.theme.colors.secondary};
    margin-top: auto;
`;

export const MAIN_CONTENT_WRAPPER_ID = 'MainContentWrapper';

const AppLayout: React.FC<AppLayoutProps> = ({
    children,
    withoutPadding,
    padTop = true,
    fullHeight,
    hideNav,
    mainContentOverflow,
    backgroundColor
}) => {
    return (
        <NavProvider>
            <Analytics />
            <AppLayoutContainer>
                <Header />
                <InnerContainer>
                    {hideNav ? null : <Sidebar />}
                    <AppContent backgroundColor={backgroundColor}>
                        <MainContentWrapper
                            id={MAIN_CONTENT_WRAPPER_ID}
                            withoutPadding={withoutPadding}
                            padTop={padTop}
                            fullHeight={fullHeight}
                            mainContentOverflow={mainContentOverflow}
                        >
                            <div style={{ zIndex: 1, backgroundColor: 'transparent' }}>{children}</div>
                        </MainContentWrapper>
                        <EmberParticles />
                    </AppContent>
                </InnerContainer>
                <FooterContainer>
                    <Footer />
                </FooterContainer>
            </AppLayoutContainer>
        </NavProvider>
    );
};

export default AppLayout;
