import React from 'react';

import './App.css';

import { ReactElement } from 'react';
import { ThemeProviderComponent } from './context/Theme';
import { RootRouter } from './pages/index.router';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';

const App = (): ReactElement => {
    return (
        <ThemeProviderComponent>
            <CssBaseline />
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <RootRouter />
            </BrowserRouter>
        </ThemeProviderComponent>
    );
};

export default App;
