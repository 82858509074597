import SocialButtons from 'components/social/SocialButtons';
import { defaultMuiTheme } from 'context/Theme/MaterialTheme';
import { SponsorLogo1, SponsorLogo2, SponsorLogo3, SponsorLogo4 } from 'img/imgconsts';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const glowAnimation = keyframes`
    0% {
        box-shadow: 0 0 25px rgba(255, 165, 0, 0.8); /* Start with a subtle glow */
    }
    50% {
        box-shadow: 0 0 40px rgba(255, 165, 0, 0.9); /* Increase the glow intensity */
    }
    100% {
        box-shadow: 0 0 25px rgba(255, 165, 0, 0.8); /* Return to subtle glow */
    }
`;

const FooterContainer = styled.div`
    position: relative;
    background-color: ${(props) => props.theme.colors.surface};
    display: flex;
    flex-direction: column;

    align-items: center;
    width: 100%;
    border-top: 1px solid ${(props) => props.theme.colors.border};
    z-index: 1; /* Add a high z-index value */

    /* Apply the glow animation to the top of the footer */
    &::before {
        content: '';
        position: absolute;
        top: -10px; /* Adjust the position as needed */
        left: 0;
        right: 0;
        height: 10px; /* Adjust the height as needed */
        background: transparent;
    }

    > p {
        font-size: 1.5rem;
    }

    ${css`
        animation: ${glowAnimation} 2s infinite alternate;
    `}
`;

const FooterRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 60px;
    gap: 2rem;

    ${defaultMuiTheme.breakpoints.down(920)} {
        flex-direction: column;
        gap: 1rem;
    }
`;

const StyledAnchor = styled.a`
    color: ${(props) => props.theme.colors.textPrimary};
    text-decoration: none;
    font-size: 2rem;
    margin-bottom: 5px;
    &:hover {
        color: ${(props) => props.theme.colors.primary};
    }

    transition: color 0.2s ease-in-out;

    ${defaultMuiTheme.breakpoints.down(600)} {
        font-size: 1.5rem;
    }
`;

const StyledImg = styled.img`
    max-height: 200px;
    max-width: 200px;

    ${defaultMuiTheme.breakpoints.down(700)} {
        max-height: 150px;
        max-width: 150px;
    }
`;

const ForgingBrainsFooter = styled.p`
    font-size: 2rem;
    // add an orange glow to the letters
    text-shadow: 0 0 5px orange;
    margin-bottom: 0.5rem;
`;

const Divider = styled.hr`
    width: 60%;
    height: 1px;
    color: ${(props) => props.theme.colors.border};
    margin: 1rem;

    ${defaultMuiTheme.breakpoints.down(600)} {
        width: 80%;
    }
`;

const Footer: React.FC = () => {
    return (
        <FooterContainer>
            <p>Thank you to our sponsors</p>
            <FooterRow>
                <a href="https://www.yukonforge.com/" target="_blank" rel="noreferrer">
                    <StyledImg src={SponsorLogo4} />
                </a>
                <a href="https://www.well-shod.com/" target="_blank" rel="noreferrer">
                    <StyledImg src={SponsorLogo1} />
                </a>
                <a href="https://www.worldchampionshipblacksmiths.com/" target="_blank" rel="noreferrer">
                    <StyledImg src={SponsorLogo2} />
                </a>
                <a href="https://farrierbox.com/home" target="_blank" rel="noreferrer">
                    <StyledImg src={SponsorLogo3} />
                </a>
            </FooterRow>
            <Divider />
            <FooterRow>
                <StyledAnchor href="/">About</StyledAnchor>
                <StyledAnchor href="/listen">Listen</StyledAnchor>
                <StyledAnchor href="/shop">Shop</StyledAnchor>
                <StyledAnchor href="/contact">Contact</StyledAnchor>
            </FooterRow>

            <div style={{ overflow: 'hidden', width: '100%', position: 'absolute', top: '-48px' }}>
                <svg
                    preserveAspectRatio="none"
                    viewBox="0 0 1200 120"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                        fill: '#D86602',
                        width: '156%',
                        height: 47,
                        transform: 'rotate(180deg) scaleX(-1)',
                        zIndex: -1
                    }}
                >
                    <path
                        d="M0 0v46.29c47.79 22.2 103.59 32.17 158 28 70.36-5.37 136.33-33.31 206.8-37.5 73.84-4.36 147.54 16.88 218.2 35.26 69.27 18 138.3 24.88 209.4 13.08 36.15-6 69.85-17.84 104.45-29.34C989.49 25 1113-14.29 1200 52.47V0z"
                        opacity=".25"
                    />
                    <path
                        d="M0 0v15.81c13 21.11 27.64 41.05 47.69 56.24C99.41 111.27 165 111 224.58 91.58c31.15-10.15 60.09-26.07 89.67-39.8 40.92-19 84.73-46 130.83-49.67 36.26-2.85 70.9 9.42 98.6 31.56 31.77 25.39 62.32 62 103.63 73 40.44 10.79 81.35-6.69 119.13-24.28s75.16-39 116.92-43.05c59.73-5.85 113.28 22.88 168.9 38.84 30.2 8.66 59 6.17 87.09-7.5 22.43-10.89 48-26.93 60.65-49.24V0z"
                        opacity=".5"
                    />
                    <path d="M0 0v5.63C149.93 59 314.09 71.32 475.83 42.57c43-7.64 84.23-20.12 127.61-26.46 59-8.63 112.48 12.24 165.56 35.4C827.93 77.22 886 95.24 951.2 90c86.53-7 172.46-45.71 248.8-84.81V0z" />
                </svg>
            </div>
            <SocialButtons />
            <br />
            <FooterRow>
                <ForgingBrainsFooter className="astriangle">Forging Brains</ForgingBrainsFooter>
            </FooterRow>
        </FooterContainer>
    );
};

export default Footer;
