import { useMediaQuery } from '@material-ui/core';
import { defaultMuiTheme } from 'context/Theme/MaterialTheme';
import React from 'react';
import styled from 'styled-components';

const MAXIMUM_HEIGHT = 650;

const Container = styled.div<{ expanded: boolean; contentHeight?: number }>`
    position: relative;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    margin: 1rem 4rem;
    overflow: hidden;

    ${defaultMuiTheme.breakpoints.down(1200)} {
        margin: 2rem 1rem;
    }
`;

const Title = styled.h2`
    font-size: 4rem;
    font-weight: bold;
    margin: 0;
    margin-bottom: 10px;
    text-shadow: 0 0 5px orange;
`;

const BodyContainer = styled.div<{ $orientation: 'left' | 'right' }>`
    background-color: ${(props) => props.theme.colors.surface};
    padding: 1rem;
    margin: auto;
    position: relative;
    ${(props) => (props.$orientation === 'right' ? 'right: 50px;' : 'left: 50px;')}
    border: 1px solid ${(props) => props.theme.colors.border};

    ${defaultMuiTheme.breakpoints.down(1200)} {
        right: 0;
        left: 0;
    }

    border-radius: 5px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
`;

const BodySegment = styled.p`
    font-size: 1.25rem;
`;

// A styled image that can lay underneath text
const StyledImage = styled.img`
    max-width: 100%;
    object-fit: contain;
    max-height: 600px;
    border-radius: 5px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);

    ${defaultMuiTheme.breakpoints.down(1200)} {
        box-shadow: none;
        max-height: 400px;
    }
`;

interface ImageWithTextProps {
    title: string;
    body: string[];
    imageUrl: string;
    textOrientation?: 'left' | 'right';
}

export const ImageWithText: React.FC<ImageWithTextProps> = ({ title, body, imageUrl, textOrientation = 'right' }) => {
    const sidebarBreakpoint = useMediaQuery(defaultMuiTheme.breakpoints.down(1200));

    return (
        <Container>
            {textOrientation === 'left' || sidebarBreakpoint ? null : <StyledImage src={imageUrl} />}
            <BodyContainer $orientation={textOrientation}>
                <Title className="astriangle">{title}</Title>
                {body.map((segment, index) => (
                    <BodySegment key={index}>{segment}</BodySegment>
                ))}
            </BodyContainer>
            {textOrientation === 'right' || sidebarBreakpoint ? null : <StyledImage src={imageUrl} />}
        </Container>
    );
};
