import { createTheme, Theme, responsiveFontSizes } from '@material-ui/core/styles';

import { LightPaletteTheme } from './LightTheme';
import modularScale from '../../utils/modularScale';

export const defaultMuiTheme = createTheme();

export const FBrainsTheme = (plugin: typeof LightPaletteTheme): Theme => {
    const theme = createTheme({
        palette: {
            type: plugin.type,
            common: {
                black: plugin.colors.black
            },
            primary: {
                main: plugin.colors.primary
            },
            secondary: {
                main: plugin.colors.secondary
            },
            error: {
                main: plugin.colors.error
            },
            warning: {
                main: plugin.colors.warning
            },
            info: {
                main: plugin.colors.info
            },
            success: {
                main: plugin.colors.success
            },
            background: {
                default: plugin.colors.background
            },
            text: {
                primary: plugin.colors.text,
                secondary: plugin.colors.textSecondary,
                disabled: plugin.colors.textDisabled,
                hint: plugin.colors.textDisabled
            },
            action: {
                active: plugin.colors.actionActive,
                hover: plugin.colors.actionHover,
                hoverOpacity: 0.04,
                selected: plugin.colors.actionActive,
                selectedOpacity: 0.08,
                disabled: plugin.colors.actionDisabled,
                disabledBackground: plugin.colors.actionFocus,
                disabledOpacity: 0.38,
                focus: plugin.colors.actionFocus,
                focusOpacity: 0.12,
                activatedOpacity: 0.12
            },
            contrastThreshold: 3
        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    body: {
                        lineHeight: 1.5,
                        fontSize: modularScale(0),
                        backgroundColor: plugin.colors.surface,
                        fontWeight: 400
                    },
                    html: {
                        WebkitFontSmoothing: 'auto'
                    }
                }
            },
            MuiDivider: {
                root: {
                    backgroundColor: plugin.colors.actionFocus
                }
            },
            MuiButton: {
                root: {
                    fontFamily: plugin.fonts.fontFamily
                },
                label: {
                    textTransform: 'none',
                    fontWeight: 500
                },
                text: {
                    padding: '6px 16px'
                },
                containedPrimary: {
                    fontWeight: 500
                }
            },
            MuiIconButton: {
                root: {
                    padding: defaultMuiTheme.spacing(1)
                }
            },
            MuiToolbar: {
                regular: {
                    minHeight: 56
                },
                gutters: {
                    [defaultMuiTheme.breakpoints.down('xl')]: {
                        paddingLeft: defaultMuiTheme.spacing(1),
                        paddingRight: defaultMuiTheme.spacing(1)
                    }
                }
            },
            // MUIRichTextEditor: {
            //     root: {
            //         border: theme.palette.action.disabled,
            //         borderWidth: 1,
            //         borderStyle: 'solid',
            //         borderRadius: theme.shape.borderRadius,
            //     },
            //     container: {
            //         margin: 0,
            //         position: 'relative',
            //     },
            //     editor: {
            //         margin: 0,
            //         padding: theme.spacing(2),
            //         minHeight: '56px',
            //     },
            //     editorContainer: {
            //         margin: 0,
            //         padding: 0,
            //     },
            //     toolbar: {
            //         borderBottom: "1px solid " + theme.palette.action.disabled,
            //         padding: theme.spacing(1),
            //     },
            //     placeHolder: {
            //         position: 'absolute',
            //         top: theme.spacing(2) + 57,
            //         left: theme.spacing(2),
            //     }
            // },
            // MuiListItemText: {
            //     primary: {
            //         fontWeight: theme.typography.fontWeightMedium
            //     }
            // },
            // MuiListItemIcon: {
            //     root: {
            //         color: 'inherit',
            //         marginRight: 0,
            //         '& svg': {
            //             fontSize: 20
            //         }
            //     }
            // },
            MuiAvatar: {
                root: {
                    width: 32,
                    height: 32
                }
            }
        },
        typography: {
            h1: {
                lineHeight: 1.6,
                [defaultMuiTheme.breakpoints.down('xs')]: {
                    lineHeight: 1.2
                }
            },
            h2: {
                lineHeight: 1.6,
                [defaultMuiTheme.breakpoints.down('xs')]: {
                    lineHeight: 1.2
                }
            },
            h3: {
                lineHeight: 1.6,
                [defaultMuiTheme.breakpoints.down('xs')]: {
                    lineHeight: 1.2
                }
            },
            h4: {
                lineHeight: 1.6,
                [defaultMuiTheme.breakpoints.down('xs')]: {
                    lineHeight: 1.2
                }
            },
            h5: {
                lineHeight: 1.6,
                [defaultMuiTheme.breakpoints.down('xs')]: {
                    lineHeight: 1.2
                }
            },
            h6: {
                lineHeight: 1.6,
                [defaultMuiTheme.breakpoints.down('xs')]: {
                    lineHeight: 1.2
                }
            }
        }
    });

    return responsiveFontSizes(theme);
};
