import React from 'react';

import loadable from '@loadable/component';
import { Route, Routes } from 'react-router-dom';
import { RouterFallback } from './RouterFallback';
import About from './About';
import Listen from './Listen';
import Shop from './Shop';
import Contact from './Contact';

const Home = loadable(() => import(/* webpackChunkName: "HomePage" */ './Home'), {
    fallback: <RouterFallback />
});

export const RootRouter = (): React.ReactElement => {
    return (
        <Routes>
            <Route path="/" element={<About />} />

            <Route path="/news" element={<Home />} />

            <Route path="/shop" element={<Shop />} />

            <Route path="/listen" element={<Listen />} />

            <Route path="/contact" element={<Contact />} />

            <Route path="*" element={<RouterFallback />} />
        </Routes>
    );
};
