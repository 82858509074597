import { ReactElement, useEffect, useRef, useState } from 'react';

import AppLayout from '../layouts/AppLayout';
import React from 'react';

import styled, { useTheme } from 'styled-components';
import { Background, Parallax } from 'react-parallax';
import { ForgingBrainsVideo_1, ForgingBrainsVideo_2 } from 'video/videoconsts';
import { WaveBackground } from 'components/overlay/wavebackground';
import { Videos } from 'components/video/videos';
import { useThemeContext } from 'context/Theme';
import { defaultMuiTheme } from 'context/Theme/MaterialTheme';

const StyledBackground = styled(Background)`
    width: 100vw;

    video {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        margin-top: -4rem;

        ${defaultMuiTheme.breakpoints.down(700)} {
            object-fit: fill;
            height: 30vh;
            margin-top: 0;
        }
    }
`;

const StyledParallax = styled(Parallax)``;

const VideoOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    z-index: 2; /* Ensure it appears above the video */
    pointer-events: none; /* Allows interaction with the video underneath */
`;

const WaveBackgroundWrapper = styled.div`
    height: 50vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    bottom: -20px;

    ${defaultMuiTheme.breakpoints.down(700)} {
        height: 25vh;
    }
`;

const VideosWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${defaultMuiTheme.breakpoints.down(700)} {
        video {
            object-fit: fill;
        }
    }
`;

const VideosTitle = styled.h1`
    font-size: 5rem;
    text-shadow: 0 0 5px orange;
    margin-top: 3rem;

    ${defaultMuiTheme.breakpoints.down(500)} {
        font-size: 4rem;
    }
`;

const Listen = (): ReactElement => {
    const videoSources = [ForgingBrainsVideo_1, ForgingBrainsVideo_2];
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const { themeMode } = useThemeContext();

    const handleVideoEnded = () => {
        // Switch to the next video in the array
        setCurrentVideoIndex((prevIndex) => (prevIndex === 0 ? 1 : 0));
    };

    // Reference to the Parallax component
    const parallaxRef = useRef<HTMLDivElement | null>(null);

    // State to track the current scroll position
    const [scrollPosition, setScrollPosition] = useState(0);

    // Function to update the scroll position state
    const handleScroll = () => {
        if (parallaxRef.current) {
            setScrollPosition(parallaxRef.current.scrollTop);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Calculate the scale based on the scroll position (adjust the scale factor as needed)
    const scale = 1 + scrollPosition * 0.5;

    return (
        <AppLayout padTop={false}>
            <StyledParallax ref={parallaxRef} strength={300}>
                <StyledBackground>
                    <video
                        autoPlay
                        muted
                        playsInline
                        width="100%"
                        height="100%"
                        onEnded={handleVideoEnded}
                        src={videoSources[currentVideoIndex]}
                        style={{
                            position: 'relative',
                            transform: `scale(${scale})`, // Apply the scale based on the scroll position
                            transformOrigin: 'center' // Center the scaling,
                        }}
                    />
                    <VideoOverlay />
                </StyledBackground>
                <WaveBackgroundWrapper>
                    <WaveBackground color={themeMode === 'dark' ? '#111212' : '#D9DBDC'} />
                </WaveBackgroundWrapper>
            </StyledParallax>
            <VideosWrapper>
                <VideosTitle className="astriangle">Latest Videos</VideosTitle>
                <Videos />
            </VideosWrapper>
        </AppLayout>
    );
};

export default Listen;
