import { ReactElement } from 'react';

import AppLayout from '../layouts/AppLayout';
import React from 'react';

import { ForgePicture } from 'img/imgconsts';
import ComingSoon from './ComingSoon';

const Shop = (): ReactElement => {
    return (
        <AppLayout>
            <ComingSoon />
        </AppLayout>
    );
};

export default Shop;
