import { ReactElement, useState } from 'react';

import AppLayout from '../layouts/AppLayout';
import React from 'react';

import { ForgePicture } from 'img/imgconsts';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';
import { Button, Checkbox, FormControl, FormControlLabel, TextField } from '@material-ui/core';
import { defaultMuiTheme } from 'context/Theme/MaterialTheme';
import { Alert } from '@material-ui/lab';

const ContactHeader = styled.h1`
    text-shadow: 0 0 5px orange;
    font-size: 5rem;
    margin-top: 3rem;
`;

const ContactContainer = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 80%;
    gap: 2rem;
    max-width: 1400px;

    ${defaultMuiTheme.breakpoints.down(1200)} {
        flex-direction: column;
        max-width: 100%;
    }
`;

const ContactForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.surface};
    padding: 2rem;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.colors.border};
    height: fit-content;
    width: 100%;
    gap: 1rem;

    ${defaultMuiTheme.breakpoints.down(1200)} {
        margin: 0 0.5rem;
        width: auto;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Disclaimer = styled.div`
    background-color: ${(props) => props.theme.colors.surface};
    padding: 2rem;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.colors.border};

    ${defaultMuiTheme.breakpoints.down(1200)} {
        margin: 0 0.5rem;
    }
`;

const StyledAlert = styled(Alert)`
    width: 100%;
`;
const DisclaimerText = [
    'Thank you for considering Forging Brains as a point of contact for your inquiries, both business and general in nature.',

    'Please be advised that this email form is designed for the purpose of addressing various inquiries, including but not limited to business - related matters and general questions about our services, products, and organization.',

    'By submitting your message through this form, you acknowledge and consent to the following:',

    'Your email and its contents will be received and reviewed by our team to provide responses to both business and general inquiries.',
    'While we strive to respond promptly, we cannot guarantee immediate replies, and response times may vary.',
    'Information provided in this form is not considered legal, financial, or professional advice. For specific advice or professional assistance, consult with the appropriate experts.',
    'Please refrain from sharing sensitive personal or confidential information via this form. For matters requiring confidentiality or privacy, we recommend using secure communication methods or scheduling a private consultation.',
    'Forging Brains values your inquiries and strives to assist you with both business and general questions to the best of our ability. We appreciate your interest in our organization and look forward to engaging with you on various topics.',

    'Sincerely,',

    'Forging Brains Team'
];

const Contact = (): ReactElement => {
    const [contactResponse, setContactResponse] = useState('');
    const [sending, setSending] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const handleFormChange = () => {
        // Check if all required fields are filled out
        const nameField = document.getElementById('name') as HTMLInputElement;
        const emailField = document.getElementById('email') as HTMLInputElement;
        const messageField = document.getElementById('message') as HTMLInputElement;
        const checkbox = document.getElementById('checkbox') as HTMLInputElement;

        const isNameValid = nameField.value.trim() !== '';
        const isEmailValid = emailField.value.trim() !== '' && emailField.checkValidity();
        const isMessageValid = messageField.value.trim() !== '';
        const isCheckboxChecked = checkbox.checked;

        // Update the isFormValid state
        setIsFormValid(isNameValid && isEmailValid && isMessageValid && isCheckboxChecked);
    };

    return (
        <AppLayout imagesrc={ForgePicture} padTop={false}>
            <Wrapper>
                <ContactHeader className="astriangle">Contact</ContactHeader>
                <ContactContainer>
                    <Disclaimer>
                        <h2>Disclaimer</h2>
                        {DisclaimerText.map((text) => {
                            return <p>{text}</p>;
                        })}
                    </Disclaimer>
                    <ContactForm
                        onSubmit={(e) => {
                            e.preventDefault();
                            setSending(true);
                            emailjs.sendForm('service_3hmbvj3', 'template_ip69wud', e.target, 'zQlbMbE3cHGJmOu8z').then(
                                (result) => {
                                    console.log(result.text);
                                    setContactResponse(result.text);
                                    setSending(false);
                                },
                                (error) => {
                                    console.log(error.text);
                                    setContactResponse(error.text);
                                    setSending(false);
                                }
                            );
                        }}
                    >
                        {contactResponse ? (
                            <StyledAlert severity={contactResponse === 'OK' ? 'success' : 'error'}>
                                {contactResponse === 'OK'
                                    ? 'Email successfully sent!'
                                    : 'There was an error sending your email. Please try again later.'}
                            </StyledAlert>
                        ) : null}
                        <TextField
                            id="name"
                            label="Your Name"
                            type="text"
                            multiline
                            fullWidth
                            required
                            variant="outlined"
                            name="from_name"
                            onChange={handleFormChange}
                        />

                        <TextField
                            id="email"
                            label="Your Email Address"
                            multiline
                            fullWidth
                            required
                            type="email"
                            variant="outlined"
                            name="from_email"
                            onChange={handleFormChange}
                        />

                        <TextField
                            id="message"
                            label="Message"
                            multiline
                            fullWidth
                            required
                            type="text"
                            minRows={4}
                            name="from_message"
                            variant="outlined"
                            onChange={handleFormChange}
                        />

                        <FormControl>
                            <FormControlLabel
                                control={<Checkbox required id="checkbox" />}
                                label="I have read the disclaimer"
                                onChange={handleFormChange}
                            />
                        </FormControl>

                        <Button type="submit" variant="contained" color="primary" disabled={!isFormValid || sending}>
                            Send
                        </Button>
                    </ContactForm>
                </ContactContainer>
            </Wrapper>
        </AppLayout>
    );
};

export default Contact;
