export const minorSecond = 16 / 15;
export const majorSecond = 1.125;
export const minorThird = 1.2;
export const majorThird = 1.25;
export const perfectFourth = 4 / 3;
export const augFourth = 1.414;
export const perfectFifth = 1.5;
export const minorSixth = 1.6;
export const goldenSection = 1.61803398875;
export const majorSixth = 5 / 3;
export const minorSeventh = 16 / 9;
export const majorSeventh = 1.875;
export const octave = 2;
export const majorTenth = 2.5;
export const majorEleventh = 8 / 3;
export const majorTwelfth = 3;
export const doubleOctave = 4;

interface Settings {
    base: number;
    ratio: number;
}

const scale = {
    base: 16,
    ratio: majorThird
};

// Function
function modularScale(v: number, settings?: Settings): string {
    if (settings === undefined) {
        settings = scale;
    }

    let base = settings.base;
    let ratio = settings.ratio;

    if (ratio === undefined) {
        ratio = scale.ratio;
    }
    if (base === undefined) {
        base = scale.base;
    }

    // Fast calc if not multi stranded
    if (!Array.isArray(base) || base.length === 1) {
        return Math.pow(ratio, v) * base + 'px';
    }

    // Normalize bases
    // Find the upper bounds for base values
    const baseHigh = Math.pow(ratio, 1) * base[0];

    for (let i = 1; i < base.length; i++) {
        // shift up if value too low
        while (base[i] / 1 < base[0] / 1) {
            base[i] = Math.pow(ratio, 1) * base[i];
        }

        // Shift down if too high
        while (base[i] / 1 >= baseHigh / 1) {
            base[i] = Math.pow(ratio, -1) * base[i];
        }
    }

    base.sort();

    // Figure out what base to use with modulo
    const rBase = Math.round((v / base.length - Math.floor(v / base.length)) * base.length);

    const result = Math.pow(ratio, Math.floor(v / base.length)) * base[rBase];

    return result + 'px';
}

export default modularScale;
