export const options = {
    background: {
        color: {
            value: '#0d47a1'
        },
        opacity: 0
    },
    fullscreen: false,
    fpsLimit: 120,
    interactivity: {
        events: {
            onClick: {
                enable: true,
                mode: 'push'
            },
            onHover: {
                enable: false,
                mode: 'repulse'
            },
            resize: true
        },
        modes: {
            push: {
                quantity: 4
            },
            repulse: {
                enable: false,
                distance: 200,
                duration: 0.4
            }
        }
    },
    particles: {
        color: {
            value: '#FF6600' // Ember-like color
        },
        life: {
            duration: {
                random: {
                    enable: false,
                    minimum: 10000 // Minimum duration
                }
            },
            size: {
                value: 1, // Size of embers at the end of their life
                random: true
            }
        },
        links: {
            color: '#ffffff',
            distance: 150,
            enable: false,
            opacity: 0.5,
            width: 1
        },
        move: {
            direction: 270,
            enable: true,
            random: false,
            speed: 1,
            straight: false
        },
        number: {
            density: {
                enable: true,
                area: 800
            },
            value: 20
        },
        opacity: {
            value: 1, // Initial opacity of particles
            random: true,
            anim: {
                enable: true,
                speed: 1, // Animation speed
                opacity_min: 0.1, // Minimum opacity during animation
                sync: false // Allow particles to animate independently
            }
        },
        shape: {
            type: 'circle'
        },
        size: {
            value: { min: 1, max: 3 }
        }
    },
    detectRetina: true
};
