import { lighten } from 'polished';

import { sharedTheme, PaletteModeType } from './SharedTheme';

// @TODO - replace colors like white and black throughout app with more generic names that work in both light and dark mode
const primaryDarkColors = {
    black: '#000a12' /* needs replacing throughout app */,

    white: '#FFF',
    surface: '#1D1E1F',
    floatingSurface: '#282829',

    solidHover: '#f2f4f5',
    whiteHover: '#f2f4f5',
    primary: '#F78801',
    primaryLighter: '#7ed916',
    secondary: '#6D757A',
    secondaryLighter: '#f29018',
    border: '#D1DADF',

    background: '#F9FAFA' /* deprecate for bottom surface */,
    bottomSurface: '#111212',
    backgroundSecondary: '#37474f',

    text: 'rgba(242, 248, 250, 0.95)' /* deprecate for textPrimary */,
    textPrimary: 'rgba(242, 248, 250, 0.95)',
    textSecondary: 'rgba(242, 248, 250, 0.8)',
    textTertiary: 'rgba(242, 248, 250, 0.65)',
    textDisabled: 'rgba(242, 248, 250, 0.38)',

    error: '#F44336',
    warning: '#FF9800',
    info: '#1976d2',
    success: '#4CAF50',

    videoBackground: '#000',
    videoMenuBackground: '#fff',
    videoBackgroundHover: '#9e9e9e',
    videoLoading: '#455060',
    videoActive: '#42a5f5',
    videoBorder: '#9aadc7',
    videoBorderActive: '#2196f3',

    cardBoxShadow:
        '0 0 5px 0 rgb(55 71 79 / 14%), 0 1px 10px 0 rgb(55 71 79 / 12%), 0 2px 4px -1px rgb(55 71 79 / 30%)',

    dropShadow: 'rgba(0, 0, 0, 0.2)',
    darkDropShadow: '#757575',
    codeBackground: 'rgba(0, 0, 0, 0.05)',
    icon: '#abb5bb',
    mutedIcon: '#C0CACF',
    actionHover: 'rgba(117, 164, 193, 0.20)',
    actionFocus: 'rgba(242, 248, 250, 0.12)',
    actionActive: 'rgba(26, 35, 39, 0.09)',
    actionDisabled: 'rgba(255, 255, 255, 0.26)',
    selectedActionDisabled: '#81bbde',
    pollViewerBorderHover: '#2a9fd8',
    pollViewerTextColorHover: '#0277BD',
    pollViewerBackgroundColorHover: 'rgba(2,119,189,0.1)',
    lightGrey: '#8f9799',
    secondaryLightGrey: '#8B959C',
    darkGrey: '#d1dadf',
    errorText: '#B71C1C',
    componentHighlight: '#ECEFF1',
    previewHighlight: '#E1F5FE',
    navItemHighlight: 'rgba(207, 216, 220, 0.1)',
    link: '#168dcb',

    glow: '#FDFDB4',
    steel: '#6D757A'
};

// colors derived from primary light colors
const derivedDarkColors = {
    primaryDarker: lighten(0.1, primaryDarkColors.primary)
};

export const darkColors = {
    ...primaryDarkColors,
    ...derivedDarkColors
};

export const DarkPaletteTheme = {
    type: PaletteModeType.dark,
    colors: darkColors,
    ...sharedTheme
};

export default DarkPaletteTheme;
