enum IconSize {
    ExtraSmall = '0.4rem',
    Small = '0.5rem',
    Medium = '0.75rem',
    Large = '1rem',
    ExtraLarge = '1.25rem',
    Heroic = '1.5rem'
}

export default IconSize;
